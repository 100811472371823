.screen-container__container {
  background-color: #000000;
  overflow: hidden;
  display: inline-flex;
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}

.screen-container__content {
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  transition-property: opacity;
  transition-duration: 0.3s;
  -webkit-overflow-scrolling: touch;
}
