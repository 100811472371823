$color-dark: #000000;
$color-background: #000000;
$color-footer: #212121;
$color-overlay-background: #535045;
$color-blue: #36a5f0;
$color-bright-blue: #00f2ff;
$color-dark-blue: #090f36;
$color-pink: #fd416a;
$color-purple: #445ff0;
$color-green: #63b700;
$color-green-50: #376700;
$color-yellow: #f6b700;
$color-yellow-50: #594300;
$color-red: #fd416a;
$color-red-50: #8d233a;
$color-light: #ffffff;
$color-light-96: rgba(255, 255, 255, 0.96);
$color-light-88: rgba(255, 255, 255, 0.88);
$color-light-80: rgba(255, 255, 255, 0.8);
$color-light-70: rgba(255, 255, 255, 0.7);
$color-light-60: rgba(255, 255, 255, 0.6);
$color-light-50: rgba(255, 255, 255, 0.5);
$color-light-40: rgba(255, 255, 255, 0.4);
$color-light-30: rgba(255, 255, 255, 0.3);
$color-light-20: rgba(255, 255, 255, 0.2);
$color-light-10: rgba(255, 255, 255, 0.1);
$color-dark-80: rgba(0, 0, 0, 0.8);
$color-dark-70: rgba(0, 0, 0, 0.7);
$color-dark-60: rgba(0, 0, 0, 0.6);

@mixin font-body() {
  font-size: 14px;
  text-align: left;
  color: $color-light;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

@mixin font-body-small-medium() {
  @include font-body();
}

@mixin font-body-small-medium-uppercase() {
  @include font-body();
  font-weight: 500;
  text-transform: uppercase;
}

@mixin font-body-small() {
  @include font-body();
  font-size: 12px;
}

@mixin font-body-small-uppercase() {
  @include font-body-small();
  font-weight: 800;
  text-transform: uppercase;
}

@mixin font-body-medium() {
  text-align: start;
  font-style: normal;
  font-family: 'BlackSans-CondensedMedium', Helvetica, Arial, serif;
}

@mixin font-body-bold() {
  text-align: left;
  font-style: normal;
  font-family: 'BlackSans-CondensedBold', Helvetica, Arial, serif;
}

@mixin font-body-bold-small() {
  @include font-body-bold();
  font-size: 12px;
}

@mixin font-body-bold-small-medium() {
  @include font-body-bold();
  font-size: 14px;
}

@mixin font-body-bold-medium() {
  @include font-body-bold();
  font-size: 16px;
}

@mixin font-button-default() {
  font-size: 19px;
  line-height: 32px;
  text-align: center;
  color: rgba(255, 255, 255, 0.96);
  font-style: normal;
  font-family: 'BlackSans-Bold', Helvetica, Arial, serif;
}

@mixin font-button-answer() {
  @include font-body-medium();
  font-size: 16px;
  color: $color-light;
  text-align: start;
}

@mixin font-title() {
  color: $color-light;
  text-align: start;
  font-style: normal;
  font-family: 'BlackSans-Bold', Helvetica, Arial, serif;
}

@mixin font-title-1-uppercase() {
  @include font-title();
  font-size: 48px;
  letter-spacing: -3px;
  text-transform: uppercase;
}

@mixin font-title-2-uppercase() {
  @include font-title();
  font-size: 40px;
  letter-spacing: -0.6px;
  text-transform: uppercase;
}

@mixin font-title-3() {
  @include font-title();
  font-size: 32px;
  letter-spacing: -0.6px;
}

@mixin font-title-3-uppercase() {
  @include font-title-3();
  text-transform: uppercase;
}

@mixin font-title-4() {
  @include font-title();
  font-size: 24px;
}

@mixin font-title-4-uppercase() {
  @include font-title-4();
  text-transform: uppercase;
}

@mixin font-title-5() {
  @include font-title();
  font-size: 20px;
  letter-spacing: -0.4px;
}

@mixin font-title-5-uppercase() {
  @include font-title-5();
  text-transform: uppercase;
}

@mixin font-title-7() {
  @include font-title();
  font-size: 16px;
}

@mixin font-title-7-uppercase() {
  @include font-title-7();
  text-transform: uppercase;
}

@mixin font-sub-title() {
  color: $color-light;
  text-align: start;
  font-style: normal;
  font-family: 'BlackSans-SemiBold', Helvetica, Arial, serif;
}
