@import '../_style-params';

.application__container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
// @media (min-width: 961px) and (min-aspect-ratio: 545/730) {
//   .application__container {
//     position: relative;
//     width: calc(100vh * (545 / 730));
//     height: 100%;
//     margin: 0 auto 0 auto;
//     overflow: hidden;
//     // border: 1px solid red;
//   }
// }
.application__screens {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.application__loading {
  @include font-sub-title(); // Added font here to preload
  opacity: 0;
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 100px;
}

.application__loading--error {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 100px;
}

.application__story-progress {
  position: fixed;
  width: 100%;
  top: 0;
  padding: 26px 32px 4px 48px;
}

.application__draft-watermark {
  position: fixed;
  top: 16px;
  right: -26px;
  padding: 4px 32px 4px 32px;
  transform: rotate(45deg);
  background-color: $color-pink;
  color: $color-dark;
  font-weight: 900;
  text-transform: uppercase;
  pointer-events: none;
}

.application__details-styling {
  width: 100%;
  user-select: none;
  .reg {
    position: relative;
    margin-top: 5px;
  }

  .bol {
    position: relative;
    font-size: 17px;
    margin-bottom: 0px;
    line-height: 18px;
    text-align: left;
    color: #ffffff;
    letter-spacing: -0.34px;
    font-stretch: condensed;
    font-style: normal;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }

  .but {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 48px;
    padding: 12px 32px;
    border-radius: 8px;
    background-color: #000000;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    text-align: center;
    word-wrap: normal;
    color: rgba(255, 255, 255, 0.96);
    font-style: normal;
    font-weight: bold;
    font-family: 'Black Sans', Helvetica, Arial, serif;
    margin: 16px auto;
  }
  .but .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2718%27%20height%3D%2716%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M7.5%2012a.723.723%200%200%200%20.531-.219L15.75%204l-.063%202.094c0%20.25.084.463.25.64a.823.823%200%200%200%20.625.266h.563c.25%200%20.458-.083.625-.25a.848.848%200%200%200%20.25-.625V1.156a1.11%201.11%200%200%200-.344-.812A1.11%201.11%200%200%200%2016.844%200h-4.969a.848.848%200%200%200-.625.25.848.848%200%200%200-.25.625v.563c0%20.25.089.458.266.625.177.166.39.25.64.25L14%202.25%206.219%209.969A.723.723%200%200%200%206%2010.5c0%20.208.073.385.219.531l.75.75A.723.723%200%200%200%207.5%2012zm5%204c.417%200%20.77-.146%201.062-.438.292-.291.438-.645.438-1.062V8.719a.723.723%200%200%200-.219-.531.723.723%200%200%200-.531-.22.723.723%200%200%200-.531.22l-.5.5a.723.723%200%200%200-.219.53V14H2V4h7.75a.723.723%200%200%200%20.531-.219.723.723%200%200%200%20.219-.531.723.723%200%200%200-.219-.531l-.5-.5A.723.723%200%200%200%209.25%202H1.5c-.417%200-.77.146-1.063.438A1.447%201.447%200%200%200%200%203.5v11c0%20.417.146.77.437%201.062.292.292.646.438%201.063.438h11z%27%20fill%3D%27%23FFF%27%20fill-rule%3D%27nonzero%27%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
  }
  .but a {
    color: rgba(255, 255, 255, 0.96);
  }

  a {
    color: #00a8e0;
    text-decoration: none;
  }
}
