.media__background {
  overflow: hidden;
  &--video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &--image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
