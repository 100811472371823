@import '../../_style-params';

@mixin transitionOpacity {
  transition-property: opacity;
  transition-duration: 0.75s;
}

.competition__container {
  position: absolute;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  user-select: none;
}

.competition__top-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.5) 62%,
    rgba(0, 0, 0, 0.8) 75%,
    $color-background
  );
}

.competition__playButton {
  margin-top: 24px;
  margin-right: 64px;
}

.competition__shareButton {
  margin-top: 24px;
  margin-right: 64px;
}

.competition__bottom-container {
  padding: 0px;
  background-color: $color-background;
  width: 100%;
}

.competition__details {
  overflow: hidden;
  position: relative;
  padding: 0px 32px 16px 32px;
  user-select: none;
}
