@import '../../../_style-params.scss';

$top_space: 0px;

.leaderboard-details__container {
  position: absolute;
  background-color: #000000;
  padding: 16px;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.leaderboard-details__copy {
  width: 100%;
}
