.screen_container__background {
  overflow: hidden;
  position: absolute;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  &--video {
    width: 100%;
    //height: 100%;
    // object-fit: cover;
    object-position: 50% 0;
  }
  &--image {
    width: 100%;
    // height: 100%;
    // object-fit: cover;
    object-position: 50% 0;
  }
  animation-fill-mode: forwards;
}

.screen_container__embed-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: absolute;
  padding: 48px 8px 0px 8px;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}

.screen_container__background-gradient {
  position: absolute;
  pointer-events: none;
  bottom: 0px;
  width: 100%;
  height: 50vh;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5)
  );
}

$scale-background-transform: 1.2;
$scale-background-distance: 50px;

@keyframes kenburns1 {
  0% {
    transform: scale3d(1, 1, 1) translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  100% {
    transform: scale3d(
        $scale-background-transform,
        $scale-background-transform,
        1
      )
      translate3d(0, $scale-background-distance, 0px);
  }
}
