.latest__container {
  width: 100%;
  user-select: none;
}

.latest__foreground-container {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.latest__leaderboard-container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.latest__multigameleaderboard-container {
  margin-top: 64px;
}

.latest__challenges-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: visible;
  column-gap: 16px;
}

.latest__vendor-container {
  margin-left: -12px;
}
