@import '../../_style-params';

.comp-details__container {
  position: absolute;
  background-color: $color-dark;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}

.comp-details__header-and-tabs {
  position: sticky;
  z-index: 1;
}

.comp-details__header {
  margin-top: 0px;
}

.comp-details__users-entrant-item {
  // position: sticky;
  z-index: 0;
}

.comp-details__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comp-details__list {
  margin-top: 0px;
}

.comp-details__back-button {
  margin-top: 16px;
  width: 80%;
}

.comp-details__overlay {
  z-index: 1;
}

.comp-details__editable-questions-container {
  width: 100%;
  margin-top: 32px;
  margin-bottom: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.comp-details__chat-summary-container {
  width: 100%;
  margin-top: 32px;
  margin-bottom: -16px;
  padding-left: 16px;
  padding-right: 16px;
}
